import React from 'react'
import "./testimonials.css";

const testimonials = () => {
  return (
    <section id='testimonials'>
    </section>
  )
}

export default testimonials