/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/1.png"
import IMG2 from "../../assets/5.png";
import IMG3 from "../../assets/8.png"
import IMG4 from "../../assets/4.png";
import IMG5 from "../../assets/portfolio1.jpg"
import IMG6 from "../../assets/9.png"



const data = [
  {
    id: 1,
    Image: IMG1,
    Title: "Modern ECommerce Dashboard",
    Github: "https://www.github.com",
    Dribble: "https://jam.dev/c/7c157689-f87f-4a92-aef7-50e786e4cf7e",
  },
  {
    id: 2,
    Image: IMG2,
    Title: "Sell And Accquire Properties Seamlessly",
    Github: "https://www.github.com",
    Dribble: "https://jam.dev/c/377e27d0-19df-4d50-ae4f-8bc2dff356b1",
  },
  {
    id: 3,
    Image: IMG3,
    Title: "Mend Is Your Digital Property Manager",
    Github: "https://www.github.com",
    Dribble: "https://jam.dev/c/142e43e1-3175-4591-bfb9-dcdb2ec14daa",
  },
  {
    id: 4,
    Image: IMG4,
    Title: "Kanban, Helps You Manage Your Schedules",
    Github: "https://www.github.com",
    Dribble: "https://www.dribble.com",
  },
  {
    id: 5,
    Image: IMG5,
    Title: "Fintech, Pay Seamlessly through usepaidly.com ",
    Github: "https://www.github.com",
    Dribble: "https://www.dribble.com",
  },
  {
    id: 6,
    Image: IMG6,
    Title: "Hire Competent marketer for your product",
    Github: "https://www.github.com",
    Dribble: "https://jam.dev/c/d76c0c9b-0696-4bec-b059-ddc75044f2d8",
  },
];


const portfolio = () => {
  return (
    <section id='portfolio'>
       <h5>My Recent Works</h5>
       <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          data.map(({ id, Image, Title, Github, Dribble }) => {
            return (
            <article key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
            <img src={Image} alt={Title} style={{width:"100%", height:"100%"}} />
            </div>
                <h3>{ Title}</h3>
            <div className="portfolio__item-cta">
            <a href={Github} className='btn' 
            target="_blank">Github</a>
            <a href={Dribble} className='btn'
            target="_blank">Live Demo</a>
            </div>
            </article> 
            )
          })
        }
      </div>
    </section>
  )
}

export default portfolio
