/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import "./about.css";
import Me from "../../assets/Moseley.jpeg";
import { FaAward } from "react-icons/fa"
import { FiUsers } from "react-icons/fi"
import {VscFolderLibrary} from "react-icons/vsc"


const about = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="About image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              {" "}
              {/* first card */}
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>2+ years experience</small>
            </article>
            <article className="about__card">
              {" "}
              {/* second  card */}
              <FiUsers className="about_icon" />
              <h5>Clients</h5>
              <small>35+ clients WorldWide</small>
            </article>
            <article className="about__card">
              {" "}
              {/* Third card */}
              <VscFolderLibrary className="about_icon" />
              <h5>Projects</h5>
              <small>27+ completed</small>
            </article>
          </div>
          <p>
            I am a results-oriented frontend developer with a proven track
            record of success. I have over 2 years of experience in HTML, CSS,
            JavaScript, React, Firebase, Tailwind css, Rest APIs and lots more, and I am proficient in all
            aspects of the frontend development lifecycle. I have a strong
            understanding of web accessibility and cross-browser compatibility,
            and I am always eager to learn new technologies.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's chat
          </a>
        </div>
      </div>
    </section>
  );
}

export default about
